<template>
  <div class="mallFinished">
    <div class="success">
      <div class="success-t">
        <img src="@/assets/img/ivon_success.png" alt="" />
      </div>
      <div class="success-b">兑换成功</div>
    </div>
    <div class="goods">
      <div class="goods-l">
        <img :src="orderDetail.skuImageUrl" alt="" />
      </div>
      <div class="goods-r">{{ orderDetail.changeTitle }}</div>
    </div>
    <div class="orderInfo">
      <div class="item">
        <div class="item-l">支付积分：</div>
        <div class="item-r">{{ orderDetail.pointsChangeNum }}</div>
      </div>
      <div class="item">
        <div class="item-l">兑换数量：</div>
        <div class="item-r">{{ orderDetail.exchangeGoodsNum }}</div>
      </div>
      <div class="item">
        <div class="item-l">兑换时间：</div>
        <div class="item-r">{{ orderDetail.insertTime }}</div>
      </div>
      <div class="item te">
        <div class="item-l">订单号：</div>
        <div class="item-r">{{ orderDetail.orderSn }}</div>
      </div>
    </div>
    <div class="tipInfo">
      <div class="tipInfo-l">
        <img src="@/assets/img/orderTip.png" alt="" />
      </div>
      <div class="tipInfo-r">温馨提示：积分兑换成功后，不接受退换货</div>
    </div>
    <div class="more" @click="lookMore">查看更多订单</div>
  </div>
</template>

<script>
import { getOrderDetailUrl } from "./api.js";
import { getHashParam, handleImg } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "mallFinished",
  data() {
    return {
      orderDetail: {},
      id: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getActivityDetail();
  },
  methods: {
    lookMore() {
      this.$router.replace({
        name: "mallOrderList",
      });
    },
    getActivityDetail() {
      let params = {
        id: this.id,
      };

      this.$axios
        .post(`${getOrderDetailUrl}`, this.$qs.stringify(params))
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.orderDetail = res.data;
            if (this.orderDetail.skuImageUrl) {
              this.orderDetail.skuImageUrl = handleImg(
                375,
                375,
                this.orderDetail.skuImageUrl
              );
            }
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.mallFinished {
  padding: 40px;
  .success {
    .success-t {
      width: 140px;
      height: 140px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .success-b {
      font-size: 36px;
      text-align: center;
      margin-top: 20px;
    }
  }
  .goods {
    display: flex;
    margin: 40px 0;
    align-items: center;
    padding: 40px;
    background: #f7f7f7;
    border-radius: 10px;
    .goods-l {
      width: 120px;
      height: 120px;
      margin-right: 40px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .goods-r {
      font-size: 30px;
      line-height: 32px;
    }
  }
  .orderInfo {
    margin-top: 100px;
    .item {
      display: flex;
      align-items: center;
      font-size: 32px;
      margin: 20px 0;
      color: #999;
      .item-l {
        width: 180px;
      }
      .item-r {
        flex: 1;

        word-break: break-all;
      }
    }
    .te {
      align-items: flex-start;
    }
  }
  .tipInfo {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 180px;
    .tipInfo-l {
      width: 30px;
      height: 30px;
      font-size: 0;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tipInfo-r {
      color: #999;
      font-size: 30px;
    }
  }
  .more {
    width: 90%;
    box-sizing: border-box;
    height: 90px;
    border-radius: 10px;
    background: rgba(0, 126, 255, 1);
    font-size: 32px;
    line-height: 90px;
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
</style>
<style lang="less">
.mallFinished {
  .body {
    padding: 20px 0;
    font-size: 30px;

    img {
      width: 100%;
    }
    font-size: 30px !important;
    span {
      font-size: 30px !important;
    }
  }
}
</style>
